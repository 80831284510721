import { SubscriptionDto } from '../../models/subscription.model';
import { DocumentToUpload, SubscriptionContext } from '../../models/subscription-context.model';
import { SubscriptionService } from '../../services/subscription.service';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { DocumentDto } from '../../models/document.model';
import { OnInit } from '@angular/core';
import { AlertService, FileRef, FileService } from 'base-lib';

export abstract class AbstractSubscriptionPipelineComponent implements OnInit {

    public subscription: SubscriptionDto;
    public subscriptionContext: SubscriptionContext;

    protected constructor(protected alertService: AlertService,
                          protected fileService: FileService,
                          protected subscriptionService: SubscriptionService) {}

    ngOnInit(): void {
        this.subscription = new SubscriptionDto();
        this.subscriptionContext = new SubscriptionContext();
    }

    protected getSubmitWorkObservable(): Observable<any> {
        return forkJoin(
            this.uploadPicture(),
            this.uploadDocuments()
        )
            .pipe(
                switchMap(([picture, documents]) => this.sendSubscription(this.subscription, picture, documents)),
                switchMap(subscription => this.alertService.success(
                    this.subscription.person.email ? 'notification.success.subscription.standard.title' : 'notification.success.subscription.linking.title',
                    this.subscription.person.email ? 'notification.success.subscription.standard' : 'notification.success.subscription.linking'))
            );
    }

    protected sendSubscription(subscription: SubscriptionDto, pictureFileRef: FileRef, documents: DocumentDto[]): Observable<any> {
        if (pictureFileRef) {
            subscription.person.pictureBinaryDataId = pictureFileRef.id;
        }
        subscription.documents = documents;
        subscription.questionnaire.generateReport();
        return this.subscriptionService.send(subscription);
    }

    protected uploadDocuments(): Observable<DocumentDto[]> {
        if (this.subscriptionContext.documentsToUpload.length === 0) {
            return of([]);
        }
        return forkJoin(
            this.subscriptionContext.documentsToUpload
                .filter(documentToUpload => documentToUpload.file !== undefined && documentToUpload.file !== null)
                .map(document => this.uploadDocument(document))
        );
    }

    protected uploadDocument(document: DocumentToUpload): Observable<DocumentDto> {
        return this.fileService
            .uploadFile(document.file)
            .pipe(
                tap(fileRef => document.document.binaryDataId = fileRef.id),
                map(fileRef => document.document)
            );
    }

    protected uploadPicture(): Observable<FileRef> {
        return this.fileService
            .uploadFile(this.subscriptionContext.pictureToUpload);
    }
}
